import { disallowedStatuses } from '../../features/document-list/filtering/constants';
import { Status } from '../../types/annotationStatus';

type ExcludedStatuses = (typeof disallowedStatuses)[number];

type SupportedStatus = Exclude<Status, ExcludedStatuses>;

export const isStatusVisible = (s: Status): s is SupportedStatus =>
  !(disallowedStatuses as readonly string[]).includes(s);

export type FilterableAnnotationOptions =
  | 'created_at'
  | 'modified_at'
  | 'exported_at'
  | 'confirmed_at'
  | 'rejected_at'
  | 'deleted_at';
